import { useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const FORCE_SIMPLE_RECOMMENDATIONS_DEFAULT = false;
export const forceSimpleRecommendationsAtom = atomWithStorage(
  "forceSimpleRecommendations-v1",
  FORCE_SIMPLE_RECOMMENDATIONS_DEFAULT,
);

const TIMER_ATOM_DEFAULT = 0;
export const timerAtom = atomWithStorage<number>(
  "browserStreamTimer-v1",
  TIMER_ATOM_DEFAULT,
);

const IS_MUTED_ATOM_DEFAULT = false;
export const isMutedAtom = atomWithStorage<boolean>(
  "browserStreamIsMuted-v1",
  IS_MUTED_ATOM_DEFAULT,
);

const PREFERS_SELFIE_CAM_ATOM_DEFAULT = false;
export const preferSelfieCamAtom = atomWithStorage<boolean>(
  "browserStreamPreferSelfieCam-v1",
  PREFERS_SELFIE_CAM_ATOM_DEFAULT,
);

const CLIENT_ACCOUNT_VIEW_ATOM_DEFAULT = -1;
export const clientAccountViewAtom = atomWithStorage<number>(
  "clientAppAccountView-v2",
  CLIENT_ACCOUNT_VIEW_ATOM_DEFAULT,
);

export type Voice = "meta" | "pete" | "woman" | "legacy" | "muted";
const VOICE_ATOM_DEFAULT: Voice = "pete";
export const voiceAtom = atomWithStorage<Voice>(
  "userVoice-v1",
  VOICE_ATOM_DEFAULT,
);

const COUNTDOWN_DURATION_ATOM_DEFAULT = 10;
export const countdownDurationAtom = atomWithStorage<number>(
  "userCountdownDuration-v1",
  COUNTDOWN_DURATION_ATOM_DEFAULT,
);

const RESET_BETWEEN_SETS_DURATION_ATOM_DEFAULT = 10;
export const restBetweenSetsDurationAtom = atomWithStorage<number>(
  "restBetweenSetsDurationAtom-v1",
  RESET_BETWEEN_SETS_DURATION_ATOM_DEFAULT,
);

const REP_DURATION_ATOM_DEFAULT = 2;
export const repDurationAtom = atomWithStorage<number>(
  "repDuration-v1",
  REP_DURATION_ATOM_DEFAULT,
);

const PREFERS_CONDENSED_EXERCISE_HISTORY_ATOM_DEFAULT = false;
export const prefersCondensedExerciseHistoryAtom = atomWithStorage<boolean>(
  "prefersCondensedExerciseHistory-v1",
  PREFERS_CONDENSED_EXERCISE_HISTORY_ATOM_DEFAULT,
);

const EXPANDED_SIDE_NAV_ATOM_DEFAULT = true;
export const expandedSideNavAtom = atomWithStorage<boolean>(
  "expandedSideNav",
  EXPANDED_SIDE_NAV_ATOM_DEFAULT,
);

const APP_USER_ID_OVERRIDE_DEFAULT = "";
export const appUserIdOverrideAtom = atomWithStorage<string>(
  "appUserIdOverride",
  APP_USER_ID_OVERRIDE_DEFAULT,
);

export const useResetAllPersistedUserPreferences = () => {
  const setExpandedSideNav = useSetAtom(expandedSideNavAtom);
  const setPrefersCondensedExerciseHistory = useSetAtom(
    prefersCondensedExerciseHistoryAtom,
  );
  const setRepDuration = useSetAtom(repDurationAtom);
  const setRestBetweenSetsDuration = useSetAtom(restBetweenSetsDurationAtom);
  const setCountdownDuration = useSetAtom(countdownDurationAtom);
  const setVoice = useSetAtom(voiceAtom);

  const setTimer = useSetAtom(timerAtom);
  const setIsMuted = useSetAtom(isMutedAtom);
  const setPrefersSelfieCam = useSetAtom(preferSelfieCamAtom);

  const setClientAccountView = useSetAtom(clientAccountViewAtom);

  const setForceSimpleRecommendations = useSetAtom(
    forceSimpleRecommendationsAtom,
  );

  const setAppUserIdOverride = useSetAtom(appUserIdOverrideAtom);

  return () => {
    setExpandedSideNav(EXPANDED_SIDE_NAV_ATOM_DEFAULT);
    setPrefersCondensedExerciseHistory(
      PREFERS_CONDENSED_EXERCISE_HISTORY_ATOM_DEFAULT,
    );
    setForceSimpleRecommendations(FORCE_SIMPLE_RECOMMENDATIONS_DEFAULT);

    setRepDuration(REP_DURATION_ATOM_DEFAULT);
    setRestBetweenSetsDuration(RESET_BETWEEN_SETS_DURATION_ATOM_DEFAULT);
    setCountdownDuration(COUNTDOWN_DURATION_ATOM_DEFAULT);
    setVoice(VOICE_ATOM_DEFAULT);
    setClientAccountView(CLIENT_ACCOUNT_VIEW_ATOM_DEFAULT);
    setTimer(TIMER_ATOM_DEFAULT);
    setIsMuted(IS_MUTED_ATOM_DEFAULT);
    setPrefersSelfieCam(PREFERS_SELFIE_CAM_ATOM_DEFAULT);
    setAppUserIdOverride(APP_USER_ID_OVERRIDE_DEFAULT);
  };
};
