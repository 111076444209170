import { Trans } from "@lingui/macro";
import Logout from "@mui/icons-material/Logout";
import PersonPinCircleRounded from "@mui/icons-material/PersonPinCircleRounded";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import { useRef } from "react";
import { loginAtom } from "../../api-hooks/auth/login";
import { logoutCallback } from "../../api-hooks/auth/logoutCallback";
import { noAuthPaths } from "../../api-hooks/auth/useAuthentication";
import { useUserDetails } from "../../api-hooks/auth/useUserDetails";
import { useAccount } from "../../api-hooks/useAccounts";
import { Authorized } from "../../shared/Auth/Authorized";
import { useResetAllPersistedUserPreferences } from "../../shared/persistedUserPreferences";
import { button } from "../../styles";
import { i18nHardCoded } from "../../translations/utils";
import { IfViewportWidth } from "../IfViewportWidth";
import { EgoscueLogo } from "./EgoscueLogo";
import style from "./Header.module.css";
import { TaskNotifications } from "./TaskNotifications";

const noHeaderPathStarts = ["/app", "/menu-summary/", "/menu-survey/", "/chat"];

export const Header = () => {
  const loginState = useAtomValue(loginAtom);

  const header = useRef<HTMLHeadingElement>(null);
  const router = useRouter();

  const userDetails = useUserDetails();
  const account = useAccount({
    accountId: userDetails.data?.accountId ?? "-1",
    type: "EPeteAccountId",
  });
  const resetAllPersistedUserPreferences =
    useResetAllPersistedUserPreferences();

  return loginState.isLoggedIn &&
    userDetails.data?.outstandingEulas === undefined &&
    !noAuthPaths.includes(router.pathname) &&
    !noHeaderPathStarts.some((path) => router.pathname.startsWith(path)) ? (
    <header ref={header} className={style.header}>
      <EgoscueLogo flipped />
      <nav className={style.nav}>
        <IfViewportWidth type="min">
          {userDetails &&
            userDetails.status === "success" &&
            account.status === "success" && (
              <div style={{ paddingBottom: "1px" }}>
                <p className={style.title}>
                  {userDetails.data.givenName} {userDetails.data.familyName}
                </p>
                {userDetails.data?.userType !== "Affiliate" && (
                  <p className={style.subTitle}>
                    <PersonPinCircleRounded />{" "}
                    {i18nHardCoded(userDetails.data?.userType)}{" "}
                    <Trans>at</Trans> {account.data.Name}
                  </p>
                )}
              </div>
            )}
        </IfViewportWidth>
        <div className={button.buttonRow}>
          <Authorized
            allowedUserTypes={["Therapist", "SuperUser"]}
            hideMessage={true}
          >
            <TaskNotifications />
          </Authorized>
          <button
            onClick={() => {
              resetAllPersistedUserPreferences();
              logoutCallback();
            }}
            className={button.actionButton}
            title="Log out"
          >
            <Logout />
          </button>
        </div>
      </nav>
    </header>
  ) : (
    <></>
  );
};
