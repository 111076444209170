import { isBefore } from "date-fns/isBefore";
import { parseISO } from "date-fns/parseISO";

export const dateCompare =
  <T extends string>(
    dateFieldName: T,
    order: "OLDEST_FIRST" | "NEWEST_FIRST" = "NEWEST_FIRST",
  ) =>
  (a: Record<T, string>, b: Record<T, string>) => {
    if (a[dateFieldName] === b[dateFieldName]) {
      return 0;
    } else {
      return isBefore(parseISO(a[dateFieldName]), parseISO(b[dateFieldName])) &&
        order === "NEWEST_FIRST"
        ? 1
        : -1;
    }
  };

export const dateCompareWithFallback =
  <TDateField extends string, TAlphaField extends string>(
    dateFieldName: TDateField,
    alphaFieldName: TAlphaField,
  ) =>
  (
    a: Record<TDateField | TAlphaField, string>,
    b: Record<TDateField | TAlphaField, string>,
  ) => {
    if (a[dateFieldName] === b[dateFieldName]) {
      return alphaFieldName
        ? a[alphaFieldName].localeCompare(b[alphaFieldName])
        : 0;
    } else {
      return isBefore(parseISO(a[dateFieldName]), parseISO(b[dateFieldName]))
        ? 1
        : -1;
    }
  };
